// 体验机构信息
import request from '@/utils/request'

const experienceApi = {
  list: `/visitor`
}

export function getList(parameter) {
  return request({
    url: experienceApi.list,
    method: 'get',
    params: parameter
  })
}
