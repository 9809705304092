<!-- 体验机构信息 -->
<template>
  <div>
    <a-card style="margin-bottom: 20px" :bordered="false">
      <a-row type="flex" align="middle" :gutter="[20, 0]">
        <a-col :lg="6" :md="8" :xs="24" class="option-wrapper">
          <span>访客姓名：</span>
          <a-input v-model="query.name" allowClear placeholder="请输入姓名" style="flex: 1" @change="initData" />
        </a-col>
        <a-col :lg="6" :md="8" :xs="24" class="option-wrapper">
          <span>手机号：</span>
          <a-input
            v-model="query.cellphone"
            allowClear
            placeholder="请输入联系电话"
            style="flex: 1"
            @change="initData"
          />
        </a-col>
        <a-col :lg="6" :md="8" :xs="24" class="option-wrapper">
          <span>机构名称：</span>
          <a-input
            v-model="query.mechanismName"
            allowClear
            placeholder="请输入机构名称"
            style="flex: 1"
            @change="initData"
          />
        </a-col>
        <a-col :lg="6" :md="8" :xs="24" class="option-wrapper">
          <span>地区查询：</span>
          <a-cascader
            :show-search="{ filter }"
            style="flex: 1"
            :options="options"
            :load-data="loadData"
            placeholder="请选择地区"
            :field-names="{ label: 'name', value: 'id', children: 'children' }"
            change-on-select
            @change="onAreachange"
          />
        </a-col>
      </a-row>
    </a-card>

    <a-card title="列表" :bodyStyle="{ padding: 0 }" v-if="$auth('visitor_list')">
      <main-table ref="table" @handleDetail="handleDetail"></main-table>
    </a-card>
    <Model ref="model" />
  </div>
</template>

<script>
import * as toolApi from '@/api/tool'
import MainTable from './components/Table'
import Model from './components/Model'

export default {
  name: 'ToolList',
  components: {
    MainTable,
    Model,
  },
  data() {
    return {
      query: {
        name: undefined, // 姓名
        cellphone: undefined, // 电话
        mechanismName: undefined, // 机构名称
        provinceId: undefined, // 省
        cityId: undefined, // 市
        countyId: undefined, // 县
        pageIndex: undefined,
        pageSize: undefined,
      },
      options: [],
    }
  },
  mounted() {
    // 获取地区数据
    toolApi.getArea({ parentId: 142 }).then((res) => {
      this.options = res.data.map((item) => {
        item.isLeaf = false
        return item
      })
    })
    this.initData()
  },
  methods: {
    // 更新表格数据
    initData() {
      this.$refs.table.initData(this.query)
    },

    // 用户名称筛选
    onAreachange(value) {
      this.query.provinceId = value[0]
      this.query.cityId = value[1]
      this.query.countyId = value[2]
      this.initData()
    },
    // 请求省市区数据
    loadData(selectedOptions) {
      console.log(selectedOptions)
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      if (selectedOptions.length < 2) {
        toolApi.getArea({ parentId: targetOption.id }).then((res) => {
          targetOption.loading = false
          if (res.data.length !== 0) {
            targetOption.children = res.data.map((item) => {
              item.isLeaf = false
              return item
            })
          }
          this.options = [...this.options]
        })
      } else {
        toolApi.getArea({ parentId: targetOption.id }).then((res) => {
          targetOption.loading = false
          if (res.data.length !== 0) {
            targetOption.children = res.data
          }
          this.options = [...this.options]
        })
      }
    },
    // 查看详情
    handleDetail(record) {
      this.$refs.model.showModal(record)
    },
  },
}
</script>

<style lang="less" scoped></style>
