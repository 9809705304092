<template>
  <a-modal
    centered
    bordered
    v-model="visible"
    :width="800"
    title="详情"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    @ok="handleCancel"
    @cancel="handleCancel"
  >
    <a-card :bordered="false" :body-style="{ padding: '30px 20px 10px 20px' }">
      <a-form-model ref="form" :model="form" :labelCol="{ span: 5 }" :wrapperCol="{ span: 15 }">
        <a-form-model-item label="问题需求：">{{ form.problem }} </a-form-model-item>
      </a-form-model>
    </a-card>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      form: {}
    }
  },
  methods: {
    showModal(record) {
      this.form = record
      this.visible = true
    },
    handleCancel() {
      this.form = {}
    }
  }
}
</script>

<style lang="less" scoped></style>
