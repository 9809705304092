import request from '@/utils/request'

const toolApi = {
  list: '/wechat/assessment/user/page/list',
  detail: id => `/wechat/assessment/pc/details/${id}`,
  area: '/wechat/area'
}
// 获取省市区
export function getArea(parameter) {
  return request({
    url: toolApi.area,
    method: 'get',
    params: parameter
  })
}
// 获取所有用户
export function getList(parameter) {
  return request({
    url: toolApi.list,
    method: 'post',
    data: parameter
  })
}
// 获取用户详情
export function getDetail(parameter) {
  const { assessmentUserId } = parameter
  return request({
    url: toolApi.detail(assessmentUserId),
    method: 'get'
  })
}
